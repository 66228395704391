html,
body,
#__next {
  flex: 1;
  margin: 0;
  padding: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: white;
}

#content,
#content > .MuiContainer-root {
  flex: 1;
  display: flex;
  flex-direction: column;
}
